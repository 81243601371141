import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, LinkBox, Link, Box, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
import { FiMenu } from "react-icons/fi";
const defaultProps = {
	"quarkly-title": "Header",
	"padding": "44px 0 44px 0",
	"border-width": "0 0 0.5px 0",
	"border-style": "solid",
	"border-color": "rgba(227, 255, 57, 0.31)",
	"background": "--color-darkL1"
};
const overrides = {
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"align-items": "flex-start"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline5",
			"white-space": "nowrap",
			"children": "СакураТату"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"background": "rgba(255, 255, 255, 0)",
			"padding": "0px 0px 0px 0px",
			"md-background": "--color-dark"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"md-color": "--green"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"md-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon :closed",
			"category": "fi",
			"icon": FiMenu
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"md-color": "--light"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"md-justify-content": "center",
			"md-padding": "30px 0px 30px 0px",
			"display": "flex",
			"justify-content": "flex-end",
			"md-align-items": "center",
			"md-flex-direction": "column"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-style": "solid",
			"text-decoration-line": "initial",
			"padding": "6px 0px 6px 0px",
			"hover-color": "--green",
			"lg-margin": "0px 16px 0px 0px",
			"color": "--light",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"border-color": "rgba(255, 255, 255, 0)",
			"font": "--base",
			"margin": "0px 16px 0px 16px",
			"md-margin": "0px 0px 7px 0px",
			"href": "/index",
			"children": "Головна сторінка"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"padding": "6px 0px 6px 0px",
			"margin": "0px 16px 0px 16px",
			"text-decoration-line": "initial",
			"hover-border-color": "--color-green",
			"border-style": "solid",
			"color": "--light",
			"border-width": "1px 0px 0px 0px",
			"lg-margin": "0px 16px 0px 0px",
			"md-margin": "0px 0px 7px 0px",
			"font": "--base",
			"hover-color": "--green",
			"border-color": "rgba(255, 255, 255, 0)",
			"children": "Послуги",
			"href": "/services"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"padding": "6px 0px 6px 0px",
			"margin": "0px 0px 0px 16px",
			"hover-color": "--green",
			"transition": "color 0.3s --transitionTimingFunction-easeInOut 0s",
			"hover-border-color": "--color-green",
			"border-width": "1px 0px 0px 0px",
			"color": "--light",
			"border-color": "rgba(255, 255, 255, 0)",
			"border-style": "solid",
			"lg-margin": "0px 0px 0px 0px",
			"text-decoration-line": "initial",
			"font": "--base",
			"href": "/contacts",
			"children": "Контакти"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
		<LinkBox {...override("linkBox")}>
			<Text {...override("text")} />
		</LinkBox>
		<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
			<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
			<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
			<Box {...override("box")}>
				<Link {...override("link")} />
				<Link {...override("link1")} />
				<Link {...override("link2")} />
			</Box>
		</QuarklycommunityKitMobileSidePanel>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;